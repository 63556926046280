<template>
  <div>
    <ListPage ref="appTable" :endpoint="apiCall" :headers="headers" title="Roles" :search="search"
      :showActionsColumn="true" :showViewButton="isAllowed('viewRole')" :showEditButton="isAllowed('editRole')"
      :showDeleteButton="isAllowed('deleteRole')" @view="navigateTo(`/app/roles/view/${$event._id}`)"
      @add="navigateTo('/app/roles/0')">
      <!-- Custom Slots for Mobile View -->
      <template v-slot:item="{ item }" v-if="isMobile">
        <role-card :role="item" :level="level" @delete="removeItem"></role-card>
      </template>
      <template v-slot:action="{ item }" v-if="isTabletAndUp"><v-icon
          v-if="level == 1 || (!item.systemGenerated && isAllowed('editRole') && item.level > level)"
          @click="navigateTo(`/app/roles/${item._id}`)">mdi-pencil</v-icon>
        <v-icon v-if="level == 1 || (!item.systemGenerated && isAllowed('deleteRole') && item.level > level)"
          @click="removeItem(item._id)">mdi-delete</v-icon>
      </template>
    </ListPage>
    <alert-message v-if="msg" :error="msg"></alert-message>
  </div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import RoleCard from '@/components/RoleResponsiveCard'
import ListPage from '@/components/common/ListPage.vue'

export default {
  components: {
    ActionButton,
    RoleCard,
    ListPage
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Action", value: "action", align: "right" }
      ],
      level: 0,
      items: [],
      apiCall: appConstants.ROLES_API,
      msg: null,
    };
  },
  mounted() {
    this.initComponent();
  },
  methods: {
    async initComponent() {
      this.level = this.getUserProfile().level;
    },
    async deleteRole(id) {
      try {
        await this.deleteItem("Are you sure you want to delete this Role?", `${appConstants.ROLES_API}/${id}`);
        this.items.splice(this.items.findIndex(rec => rec._id === id), 1);
        this.$refs.appTable.loadData()
      } catch (error) {
        this.handleError(error);
        this.msg = error.response.data;
      }
    },
    removeItem(id) {
      this.deleteRole(id);
    },

    navigateTo(path) {
      this.$router.push(path);
    }
  }
};
</script>

<style scoped>
.format {
  padding: 1%;
}
</style>